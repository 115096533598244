import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { isMobileUA } from 'dpl/shared/utils';
import Icon from 'dpl/common/components/Icon';

const IS_MOBILE = isMobileUA();

export default function MiniOverlayModalCloseButton({
  color,
  className,
  onCloseClick,
  iconSize
}) {
  return (
    <button
      className={classnames(
        'MiniOverlayModalCloseButton absolute top-0 right-0 z-max',
        className || {
          'pv4 ph4': !IS_MOBILE,
          'pv2 ph2 mv3 mh3': IS_MOBILE
        },
        color
      )}
      data-test-id="mini_overlay_modal_close_button"
      onClick={onCloseClick}
      type="button"
    >
      <Icon name="fetch-close" width={iconSize} height={iconSize} />
    </button>
  );
}

MiniOverlayModalCloseButton.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  iconSize: PropTypes.string,
  onCloseClick: PropTypes.func.isRequired
};

MiniOverlayModalCloseButton.defaultProps = {
  color: 'mid-gray',
  className: null,
  iconSize: '24px'
};
