import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import FixedScrollWrapper from 'dpl/components/FixedScrollWrapper';
import OverlayModal from 'dpl/components/OverlayModal';
import MiniOverlayModalCloseButton from 'dpl/common/mini_overlay_modal/components/MiniOverlayModalCloseButton';
import { isNativeAppUA } from 'dpl/shared/utils';

export default function MiniOverlayModal({
  children,
  className,
  hideCloseButton,
  disableClose,
  maxWidth,
  onCloseClick,
  onOpen,
  scrollableContent,
  zIndexClass,
  contentClassName,
  mobileCompact,
  closeButtonColor,
  closeButtonClassName,
  closeButtonSize,
  dataTestId,
  fullScreen
}) {
  const ref = useRef();

  const bgClassName = classnames('flex justify-center items-center', {
    'pv12-md ph8-md': !fullScreen,
    'bg-black-90': fullScreen,
    'h-100': scrollableContent,
    'min-h-100': !scrollableContent
  });

  const contentsClassName = classnames(
    'MiniOverlayModal__contents w-100 relative-ns center',
    {
      'h-100': scrollableContent,
      relative: mobileCompact,
      br4: !fullScreen
    },
    contentClassName
  );

  /* eslint-disable jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events */
  return (
    <OverlayModal
      className={className}
      hideCloseButton
      onClose={onCloseClick}
      onOpen={onOpen}
      zIndexClass={zIndexClass}
      disableClose={disableClose}
      dataTestId={dataTestId}
    >
      <FixedScrollWrapper
        className={classnames('MiniOverlayModal overflow-x-hidden h-100', {
          'MiniOverlayModal--mobileCompact': mobileCompact
        })}
      >
        <div
          className={bgClassName}
          ref={ref}
          role="button"
          onClick={e => e.target === ref.current && onCloseClick()}
        >
          <div className={contentsClassName} style={{ maxWidth }}>
            {children}
            {!hideCloseButton && (
              <MiniOverlayModalCloseButton
                onCloseClick={onCloseClick}
                iconSize={closeButtonSize}
                color={closeButtonColor}
                className={closeButtonClassName}
              />
            )}
          </div>
        </div>
      </FixedScrollWrapper>
    </OverlayModal>
  );
}

MiniOverlayModal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hideCloseButton: PropTypes.bool,
  closeButtonColor: PropTypes.string,
  closeButtonSize: PropTypes.string,
  disableClose: PropTypes.bool,
  maxWidth: PropTypes.string,
  onCloseClick: PropTypes.func,
  onOpen: PropTypes.func,
  scrollableContent: PropTypes.bool,
  zIndexClass: PropTypes.string,
  contentClassName: PropTypes.string,
  closeButtonClassName: PropTypes.string,
  mobileCompact: PropTypes.bool, // allow "peeking" transparent background
  dataTestId: PropTypes.string,
  fullScreen: PropTypes.bool
};

MiniOverlayModal.defaultProps = {
  className: '',
  contentClassName: 'bg-white',
  closeButtonClassName: '',
  closeButtonSize: '24px',
  hideCloseButton: isNativeAppUA(),
  closeButtonColor: 'mid-gray',
  disableClose: false,
  maxWidth: '600px',
  onOpen: null,
  scrollableContent: false,
  zIndexClass: 'z-max',
  mobileCompact: false,
  onCloseClick: () => {},
  dataTestId: 'mini-overlay-modal',
  fullScreen: false
};
